<template>
	<div class="room">
		<div class="room_main">
			
			<div class="rm_right">
				
				<div class="rmr_list">
					<template v-for="item in deviceData">
						<div class="rmrl_div" :key="item.id">
							<div class="rmrld_name">
								<span>{{item.device_name}}</span>
								<i class="iconfont icon-bianji1" @click="editDeviceRoom(item)"></i>
							</div>
							<template v-for="json in item.propertys">
								<div class="rmrld_content" :key="json.property_key" >
									<div class="rmrldc_top" @click="toData(item,json)">
										<div class="rmrldct_left">
											<span v-if="item.device_type!='fuyoujun'">{{json.property_name}}</span>
											<span v-else>浮游菌打开次数</span>
										</div>
										<div class="rmrldct_right" >
											<i class="iconfont icon-gengduo"></i>
										</div>
									</div>
									<div class="rmrldc_bottom" v-if="item.device_type!='fuyoujun'" @click="toData(item,json)">
										<span>{{json.last_data.value}}</span>
										<span>{{json.property_unit}}</span>
									</div>
									<div class="rmrldc_bottom" v-else @click="toData(item,json)">
										<span>{{json.switch_num}}</span>
										<span>次</span>
									</div>
									<div class="action_div" v-if="item.device_type=='fuyoujun'">
										<van-switch :value="item.isOpen" @input="onInput(item)"></van-switch>
										<span v-if="item.isOpen">开启</span>
										<span v-else>关闭</span>
									</div>
								</div>
							</template>	
							<div class="rmrld_time" v-if="item.propertys && item.propertys.length>0">
								<span v-if="item.propertys[0].last_data">数据产生时间：{{getTime(item.propertys[0].last_data.data_time)}}</span>
							</div>
							

						
						</div>
					</template>
					
				</div>
			</div>
		</div>


		<van-dialog v-model="showEditRoom" title="修改房间名称" show-cancel-button @confirm="confirmRoomName">
			<div class="dialog_main">
				<div class="dm_input">
					<input placeholder="请输入房间名称" v-model="nowRoom.name"/>
				</div>
			</div>
		</van-dialog>

		<van-dialog v-model="showEditDevice" title="修改设备名称" show-cancel-button @confirm="confirmDeviceName">
			<div class="dialog_main">
				<div class="dm_input">
					<input placeholder="请输入设备名称" v-model="nowDevice.device_name"/>
				</div>
			</div>
		</van-dialog>

	
		<footer-main active="/room"></footer-main>


	</div>
</template>

<script>


import studio_login_api from '@/api/teaching_studio_no_login.js'
import studio_api from '@/api/teaching_studio_teaching_studio_api.js'
import studio_tool_api from '@/api/teaching_studio_tool.js'
import until from '@/api/until.js'
import footerMain from '@/components/footer.vue'
export default {
	name: 'Home',
	data() {
		return {
			active:0,
			showEditRoom:false,
			workShop:[],
			roomList:[],
			room_id:'',
			nowRoom:{},
			salt:"",
			deviceData:[],

			showEditDevice:false,

			nowDevice:{},
            type:"",
            work_shop_id:'',




		}
	},
	components: {
		footerMain
	},
	mounted() {
		document.title="房间数据"
        this.type=this.$route.query.type
        this.work_shop_id=this.$route.query.work_shop_id
		this.getRoomDeviceData();

		this.getSalt();
	},
	methods: {
		getTime(time){
			if(time){
				return until.formatDateHour(time*1000)
			}
			else{
				return ""
			}
			
		},
		//获取salt
		async getSalt(){
			let computer_code=until.getCookie("guid").substring(0, 6)
			let data=await studio_tool_api.ToolForm_saltGet({computer_code});
			if(data.errcode==0){
				this.salt=data.other;
			}
		},


		//获取房间里的设备以及属性
		async getRoomDeviceData(){
			let app_id=this.$root.app_id;
			let device_type=this.type
            let work_shop_id=this.work_shop_id;
			let data=await studio_api.Teaching_studio_apiIot_deviceStudioTypeListGet({app_id,device_type,work_shop_id});
			if(data.errcode==0){
				data.other.map(res=>{
					if(res.device_type=='fuyoujun'){
						res.isOpen=false;
						if(res.propertys.length>0){
							if(res.propertys[0].last_data.value=='ON'){
								res.isOpen=true
							}
							else{
								res.isOpen=false
							}
						}
					}
				})
				this.deviceData=data.other;
			}
		},
		//编辑房间名称
		editRoomName(){
			this.showEditRoom=true;
		},
		//编辑设备名称
		editDeviceRoom(item){
			this.showEditDevice=true;
			this.nowDevice=item
		},
		//确认修改房间名称
		async confirmRoomName(){
			let app_id=this.$root.app_id;
			let computer_code=until.getCookie("guid").substring(0, 6)
			let salt=this.salt;
			let work_room_id=this.room_id;
			let name=this.nowRoom.name
			

			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});

			let data=await studio_api.Teaching_studio_apiWork_roomNameUpPut({app_id,computer_code,salt,work_room_id,name});
			if(data.errcode==0){
				this.$toast("更新成功")
				this.showEditRoom=false;
				this.getSalt()
			}
			else{
				this.getSalt();
			}
		},
		//确认修改设备名称
		async confirmDeviceName(){
			let app_id=this.$root.app_id
			let computer_code=until.getCookie("guid").substring(0, 6)
			let salt=this.salt;
			let device_id=this.nowDevice.id;
			let device_name=this.nowDevice.device_name
			this.$toast.loading({
				message: "加载中...",
				forbidClick: true,
				duration: 0,
			});

			let data=await studio_api.Teaching_studio_apiIot_deviceNameUpPut({app_id,computer_code,salt,device_id,device_name});
			if(data.errcode==0){
				this.$toast("更新成功")
				this.showEditDevice=false;
				this.getSalt()
			}
			else{
				this.getSalt()
			}
		},
		toData(item,json){
			if(item.device_type!='fuyoujun'){
				this.$router.push("/data?property_id="+json.property_id+"&unit="+json.property_unit+"&deviceName="+item.device_name+"&property_key="+json.property_key)
			}
			else{
				this.$router.push("/swim?property_id="+json.property_id+"&unit="+json.property_unit+"&deviceName="+item.device_name+"&property_key="+json.property_key)
			}
			
		},
		setRoom(item){
			this.room_id=item.id;
			this.nowRoom=item
			this.getRoomDeviceData();
		},
		//是否确认切换开关
		async onInput(item){
			console.log(item)
			let title="是否确认关闭浮游菌?"
			if(!item.isOpen){
				title="是否确认打开浮游菌?"
			}
			if(item.isOpen){
				
				this.$toast("请等待设备自动关闭")
				return false;
			}
			this.$dialog.confirm({
				title: '提醒',
				message: title,
			}).then(async () => {
				let app_id=this.$root.app_id;
				let computer_code=until.getCookie("guid").substring(0, 6)
				let salt=this.salt;
				let device_id=item.id
				let event_key="openswitch1"
				let event_value=""
				this.$toast.loading({
					message: "加载中...",
					forbidClick: true,
					duration: 0,
				});
				let data=await studio_api.Teaching_studio_apiIot_deviceOrderPut({app_id,computer_code,salt,device_id,event_key,event_value});
				if(data.errcode==0){
					this.$toast.clear();
					this.$toast("命令发送成功,请等待设备响应!");
					this.getSalt()
					setTimeout(() => {
						this.getRoomDeviceData();
					}, 10000);
				}
				else{
					this.getSalt();
				}
			});
		}
	}
}
</script>
<style scoped>
.room {
	width: 100%;
	background: #F6F6F6;
	height: 100vh;
	display: inline-block;
	overflow: auto;
	padding-bottom: 1.50667rem;
	box-sizing: border-box;
}
.room_main{
	width: 100%;
	display: inline-block;
	position: relative;
	height: 100%;
}
.rm_left{
	width: 200px;
	position: absolute;
	top: 20px;
	bottom: 0;
	left: 0;
	overflow: auto;
	background: #FFFFFF;
	border-radius: 0px 20px 0px 0px;
	opacity: 1;
}
.rml_div{
	width: 100%;
	display: inline-block;

}
.rmld_top{
	width: 100%;
	line-height: 40px;
	padding: 30px 30px;
	text-align: center;
	font-size: 28px;
	color: #333333;
	font-weight: bold;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;

}
.rmld_list{
	width: 100%;
	display: inline-block;
}
.rmldl_div{
	width: 100%;
	height: 96px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.rmldl_xian{
	width: 8px;
	height: 24px;
	background: #ffffff;
	border-radius: 4px 4px 4px 4px;
	opacity: 1;

}
.rmldl_span{
	font-size: 28px;
	color: #666666;
	line-height: 40px;
	margin-left: 8px;
}
.selectrmldl_div{
	background: #F6F6F6;
}
.selectrmldl_div .rmldl_xian{
	background: #4E89FF;
}
.selectrmldl_div .rmldl_span{
	color: #4E89FF;
}

.rm_right{
	width: calc(100% - 60px);
	margin-left: 30px;

	margin-top: 20px;


	padding: 0 24px;
	box-sizing: border-box;
	display: inline-block;
	padding-bottom: 30px;
	overflow: auto;
}
.rmr_name{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 32px;
}
.rmrn_left{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	line-height: 45px;
}
.rmrn_left img{
	width: 34px;
	height: 32px;
}
.rmrn_left span{
	font-size: 32px;
	margin-left: 8px;
	color: #333333;
	font-weight: bold;
}
.rmrn_right{
	display: flex;
	align-items: center;
	justify-content: center;
}
.rmrn_right .iconfont{
	font-size: 36px;
	color: #4E89FF;
	line-height: 36px;
	
}
.rmr_list{
	width: 100%;
	display: inline-block;
}
.rmrl_div{
	width: 100%;
	padding: 0 24px;
	box-sizing: border-box;
	padding-bottom: 24px;
	display: inline-block;
	background: #fff;
	border-radius: 20px 20px 20px 20px;
	margin-top: 24px;
    
}
.rmrld_name{
	width: 100%;
	display: inline-block;
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 28px;
	color: #333333;
	font-weight: bold;
}
.rmrld_name .iconfont{
	font-size: 36px;
	color: #4E89FF;
	font-weight: 400;
}
.rmrld_content{
	width: 100%;
	
	background: #f6f6f6;
	border-radius: 20px 20px 20px 20px;
	opacity: 1;
	margin-top: 24px;
	display: inline-block;
	padding: 0 30px;
	padding-bottom: 24px;
	box-sizing: border-box;
}
.rmrldc_top{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 24px;
}
.rmrldct_left{
	font-size: 32px;
	color: #333333;
	font-weight: bold;
	line-height: 45px;
}
.rmrldct_right{
	width: 45px;
	height: 45px;
	background: #E2ECFF;
	opacity: 1;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

}
.rmrldct_right .iconfont{
	font-size: 24px;
	color: #4E89FF;
}
.rmrldc_bottom{
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	margin-top: 8px;
	font-size: 48px;
	color: #02E4AE;
	font-weight: bold;
	line-height: 59px;
}
.rmrldc_bottom span:last-child{
	font-size: 24px;
	height: 55px;
	color: #999999;
	font-weight: bold;
}
.dialog_main{
	width: 100%;
	padding: 40px;
	box-sizing: border-box;
}
.dm_input{
	width: 100%;
	height: 80px;
	background: #F6F6F6;
	border-radius: 20px 20px 20px 20px;
	overflow: hidden;
}
.dm_input input{
	width: 100%;
	padding-left: 35px;
	box-sizing: border-box;
	font-size: 28px;
	font-weight: bold;
	border: 0px;
	outline: none;
	height: 80px;
	background: #F6F6F6;
}
.action_div{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
}
.action_div span{
	font-size: 28px;
	color: #333333;
	font-weight: bold;
}
.rmrld_time{
	width: 100%;
	font-size: 24px;
	color: #999999;
	line-height: 33px;
	margin-top: 24px;
}
</style>
